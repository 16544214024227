<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">İstatistiksel Veriler</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <label>Başlangıç:</label>
            <input type="date" class="form-control" v-model="begin" />
          </div>
          <div class="col-sm-6 col-md-6">
            <label>Bitiş:</label>
            <input type="date" class="form-control" v-model="end" />
          </div>
          <div class="col-sm-6 col-md-6 mt-5">
            <div
              v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>Onaylanan Randevu Sayısı</b></h4>
              <span>{{ staticsList.alinan_randevu }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 mt-5">
            <div
            v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>Yorum</b></h4>
              <span>{{ staticsList.yorum }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div
            v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>Gerçekleşen Randevu Sayısı</b></h4>
              <span>{{ staticsList.gecerli_randevu }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div
            v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>İptal Edilen Randevu Sayısı</b></h4>
              <span>{{ staticsList.reddedilen_randevu }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div
            v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>Toplam Randevu Sayısı</b></h4>
              <span>{{ staticsList.toplam_randevu }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div
            v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>Profil Ziyareti</b></h4>
              <span>{{ staticsList.ziyaret }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div
            v-if="staticsList != ''"
              class="card p-2"
              style="border-left: #bb7494 10px solid; border-radius: 7px"
            >
              <h4><b>Telefon Tıklama</b></h4>
              <span>{{ staticsList.telefon }} Adet</span>
            </div>
            <div class="text-center mt-5 mb-5" v-else>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b> Onaylanan Randevu Sayısı</b></h4>
            <apexchart
              width="100%"
              type="area"
              :options="staticsList.chart_option"
              :series="[staticsList.alinan_gunluk_randevu_sayisi]"
            ></apexchart>
          </div>
          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b>İptal Edilen Randevu Sayısı</b></h4>

            <apexchart
              width="100%"
              type="area"
              :options="staticsList.chart_option"
              :series="[staticsList.reddedilen_gunluk_randevu_sayisi]"
            ></apexchart>
          </div>
          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b>Geçerli Randevu Sayısı</b></h4>

            <apexchart
              width="100%"
              type="area"
              :options="staticsList.chart_option"
              :series="[staticsList.gecerli_gunluk_randevu_sayisi]"
            ></apexchart>
          </div>
          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b>Toplam Randevu Sayısı</b></h4>

            <apexchart
              width="100%"
              type="bar"
              :options="staticsList.chart_option"
              :series="[staticsList.gunluk_randevu_sayisi]"
            ></apexchart>
          </div>
          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b>Profil Ziyareti</b></h4>

            <apexchart
              width="100%"
              type="line"
              :options="staticsList.chart_option"
              :series="[staticsList.ziyaret_gunluk]"
            ></apexchart>
          </div>

          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b>Telefon Tıklama</b></h4>

            <apexchart
              width="100%"
              type="line"
              :options="staticsList.chart_option"
              :series="[staticsList.telefon_gunluk]"
            ></apexchart>
          </div>
          <div class="col-sm-12 col-md-12 mt-5" v-if="staticsList != ''">
            <h4><b>Yorum</b></h4>
            <apexchart
              width="100%"
              type="line"
              :options="staticsList.chart_option"
              :series="[staticsList.yorum_gunluk]"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
export default {
  created() {
    document.title = "İstatistiksel Veriler";
    const date = new Date();
    this.begin =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-01";
    this.end =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
  },
  data() {
    return {
      staticsList: [],
      begin: "",
      end: "",
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
    };
  },
  watch: {
    end: function () {
      let experienceInfo = {
        begin: this.begin,
        end: this.end,
      };
      this.$store.dispatch("staticsList", experienceInfo).then((value) => {
        this.staticsList = value;
      });
    },
  },
  components: { apexchart },
  mounted() {},
};
</script>